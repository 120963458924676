import React from "react";
import html from '../assets/html.png'
import css from '../assets/css.png'
import javascript from '../assets/javascript.png'
import node from '../assets/node.png'
import github from '../assets/github.png'
import graphQL from '../assets/graphql.png'
import react from '../assets/react.png'
import angular from '../assets/angular.png'
import tailwind from '../assets/tailwind.png'
// import html from '../assets/html.png'

const Experience = () => {

    const techs = [
        {
            id:1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id:2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id:3,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id:4,
            src: react,
            title: 'React',
            style: 'shadow-blue-600'
        },
        {
            id:5,
            src: angular,
            title: 'Angular',
            style: 'shadow-red-600'
        },
        {
            id:5,
            src: tailwind,
            title: 'Tailwind',
            style: 'shadow-sky-400'
        },
        {
            id:6,
            src: node,
            title: 'Node',
            style: 'shadow-green-500'
        },
        {
            id:7,
            src: graphQL,
            title: 'GraphQL',
            style: 'shadow-pink-400'
        },
        {
            id:8,
            src: github,
            title: 'GitHub',
            style: 'shadow-gray-400'
        },
    ]

    return (
        <div name="experience" className="bg-gradient-to-b from-black to-gray-800 w-full">
            <div className="spacer w-full">&#160;</div>
            <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full text-white">
                <div>
                    <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">Experience</p>
                    <p className="py-6">These are the technologies I have worked with</p>
                </div>

                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12">
                    {
                        techs.map(({id,src,title,style}) => (
                            <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
                            >
                        <img src={src} alt="" className="w-20 mx-auto"/>
                        <p className="mt-4">{title}</p>
                    </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Experience;